import React from 'react';

function EventDetails() {
    const encodedAddress = "101+River+Pk+Dr,+San+Diego,+CA+92108";
    const spotifyPlaylistUrl = "https://open.spotify.com/playlist/4X1Nk0y8je5KtJL79hcwxM?si=ed2a15781f76491f&pt=3a23ece6437264ef9d59da633ea0e194"; // Replace with your playlist link

    const handleOpenMaps = (event) => {
        event.preventDefault();
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (isIOS) {
            window.open(`http://maps.apple.com/?q=${encodedAddress}`);
        } else {
            window.open(`https://www.google.com/maps?q=${encodedAddress}`);
        }
    };

    const handleOpenSpotify = (event) => {
        event.preventDefault();
        window.location.href = spotifyPlaylistUrl;
    };

    const handleOpenQrCode = (event) => {
        event.preventDefault();
        window.open('https://user.butterflymx.com/public/virtual_keys/13340215/1e4e7d9415ce7fbeb7937afa1335bd3b')
    }

    return (
        <div className="event-details">
            <h2>In case you're bad at math:</h2>
            <p className="date">Date: Saturday June 1st, 2024</p>
            <p className="time">Time: 4:00 PM</p>
            <p className="location">Location: Clubhouse on the 3rd floor at Ruby @ The Society</p>
            <button className="location-button" onClick={handleOpenMaps}>Open in Maps</button>
            <br /> {/* Line break */}
            <button className="qr-button" onClick={handleOpenQrCode}>Get QR Code to Get in The Building</button>
            <br /> {/* Line break */}
            <button className="spotify-button" onClick={handleOpenSpotify}>
                Add Some Bangers to The Playlist!
            </button>
        </div>
    );
}

export default EventDetails;
