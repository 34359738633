import React from 'react';

function Header() {
    return (
        <header>
            <h1>Zack's having a birthday bash in:</h1>
        </header>
    );
}

export default Header;
