import React, { useState, useEffect } from 'react';

function Countdown() {
    const calculateTimeLeft = () => {
        const eventDate = new Date("2024-06-01T16:00:00"); // June 1st, 2024 at 4 PM
        const difference = +eventDate - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            timeLeft = {
                message: "The bash has started!"
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    if (timeLeft.message) {
        return (
            <div className="countdown">
                <span className="card">{timeLeft.message}</span>
            </div>
        );
    } else {
        const intervals = ['days', 'hours', 'minutes', 'seconds'];
        const nonZeroFound = intervals.some(interval => timeLeft[interval] > 0);

        intervals.forEach((interval, index) => {
            if (nonZeroFound || intervals.slice(0, index).every(prev => timeLeft[prev] === 0)) {
                timerComponents.push(
                    <span key={interval} className="card">
                        {`${timeLeft[interval]}\n${interval}`}
                    </span>
                );
            }
        });

        return (
            <div className="countdown">
                {timerComponents.length ? timerComponents : <span className="card">{timeLeft.message}</span>}
            </div>
        );
    }
}

export default Countdown;
