import React from 'react';

function FAQ() {
    const faqs = [
        { question: "What should I bring?", answer: "bring some 🍸🍺🍷 ya friccin' ding dong! Snacks also are not a bad idea. Other than that, we have the space for 4 hours and may move the party to La Jolla Shores for a bonfire, so anything you'd want for that." },
        { question: "Uhh, where do I park?", answer: "The best place is probably the Fashion Valley mall on the south side of the JCPenney. It's about a 5ish minute walk from there." },
        { question: "Food?", answer: "We'll be bringing some snackies and some prepped stuff from Costco™. It would probably be best not show up starving, but there will be stuff to eat." },
        { question: "Should Julia bring cornhole?", answer: "Yes." },
        { question: "I don't get where it is. It says it's an apartment complex on google maps.", answer: "It is an apartment complex! Our good friends Anusha and Karthik live there, and we have the clubhouse reserved for this 😁" },
        { question: "Where do I go once I'm in the building?", answer: "You wanna be in the lobby, which is on the Northwest corner of the building. Head back to the elevators. The clubhouse is on the 3rd floor." },

        // Add more FAQs here
    ];

    return (
        <div className="faq">
            <h2>FAQs</h2>
            {faqs.map((faq, index) => (
                <div key={index}>
                    <p className="question">Q: {faq.question}</p>
                    <p className="answer">A: {faq.answer}</p>
                </div>
            ))}
        </div>
    );
}

export default FAQ;
