import React from 'react';
import Header from './components/Header';
import Countdown from './components/Countdown';
import EventDetails from './components/EventDetails';
import FAQ from './components/FAQ';
import './styles.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <Header />
        <Countdown />
        <EventDetails />
        <FAQ />
      </div>
    </div>
  );
}

export default App;
